/* eslint-disable no-unused-vars */

/* Application route names to be added below */
var ROUTES = {
  HOME: {
    name: "ve-dashboard",
    path: "/",
    component: "ve-dashboard"
  },
  HOME_UTMS: {
    name: "ve-dashboard-utm",
    path: "/home/:utms",
    component: "ve-dashboard"
  },
  // LISTINGS: {
  //   name: "ve-listings",
  //   path: "/listings/:filters",
  //   component: "ve-listings"
  // },
  DETAIL_VIEW: {
    name: "ve-details",
    path: "/details/:carId",
    component: "ve-details"
  },
  FEATURES_VIEW: {
    name: "ve-features",
    path: "/features/:carId",
    component: "ve-features"
  },
  GALLERY_VIEW: {
    name: "ve-gallery",
    path: "/gallery/:carId",
    component: "ve-gallery"
  },
  DRIVE_VIEW: {
    name: "drive-status",
    path: "/drive-status",
    component: "drive-status"
  },
  TEST_DRIVE_VIEW: {
    name: "ve-test-drive",
    path: "/test-drive",
    component: "ve-test-drive"
  },
  TEST_DRIVE_BOOKING_VIEW: {
    name: "ve-test-drive-booking",
    path: "/drive-booking",
    component: "ve-test-drive-booking"
  },
  ADDRESS_PIN_MAP: {
    name: "address-pin-map",
    path: "/map-address",
    component: "address-pin-map"
  },
  TEST_DRIVE_BOOKING_STATUS_VIEW: {
    name: "ve-test-drive-booking-status",
    path: "/booking-status",
    component: "ve-test-drive-booking-status"
  },
  SELLER_PITCH: {
    name: "ve-seller-pitch",
    path: "/seller-pitch",
    component: "ve-seller-pitch"
  },
  SELLER_ENTRY: {
    name: "ve-seller-entry",
    path: "/seller-entry/:section?",
    component: "ve-seller-entry"
  },
  SELLER_DETAILS: {
    name: "ve-seller-details",
    path: "/seller-details",
    component: "ve-seller-details"
  },
  SELLER_INITIAL_PRICE: {
    name: "ve-seller-initial-price",
    path: "/seller-initial-price",
    component: "ve-seller-initial-price"
  },
  SELLER_PRICE_SHEET: {
    name: "ve-seller-price-sheet",
    path: "/seller-price-sheet",
    component: "ve-seller-price-sheet"
  },
  SELLER_CHOOSE_LOCATION: {
    name: "ve-seller-choose-location",
    path: "/seller-choose-location/:section?",
    component: "ve-seller-choose-location"
  },
  SELLER_INSPECTION_AGENT: {
    name: "ve-seller-inspection-agent",
    path: "/inspection-agent",
    component: "ve-seller-inspection-agent"
  },
  SELLER_INSPECTION_PROGRESS: {
    name: "ve-seller-inspection-progress",
    path: "/inspection-progress",
    component: "ve-seller-inspection-progress"
  },
  SELLER_ESTIMATED_PRICE: {
    name: "ve-seller-estimated-price",
    path: "/estimated-price",
    component: "ve-seller-estimated-price"
  },
  SELLER_UPLOAD_DOCUMENTS: {
    name: "ve-seller-upload-documents",
    path: "/seller-upload/:section?",
    component: "ve-seller-upload-documents"
  },
  SELLER_UPLOAD_HOME: {
    name: "ve-seller-upload-home",
    path: "/seller-upload-home",
    component: "ve-seller-upload-home"
  },
  SELLER_BANK_DETAILS: {
    name: "ve-seller-bank-details",
    path: "/seller-bank-details",
    component: "ve-seller-bank-details"
  },
  SELLER_REJECT_REASON: {
    name: "ve-seller-reject-reason",
    path: "/reject-reason",
    component: "ve-seller-reject-reason"
  },
  SELLER_NEGOTIATION: {
    name: "ve-seller-negotiation",
    path: "/price-negotiation",
    component: "ve-seller-negotiation"
  },
  SELLER_PICKUP_SCHEDULE: {
    name: "ve-seller-pickup-schedule",
    path: "/pickup-schedule",
    component: "ve-seller-pickup-schedule"
  },
  SELLER_PICKUP_AGENT: {
    name: "ve-seller-pickup-agent",
    path: "/pickup-agent/:section?",
    component: "ve-seller-pickup-agent"
  },
  SELLER_PICKUP_COMPLETE: {
    name: "ve-seller-pickup-complete",
    path: "/pickup-complete",
    component: "ve-seller-pickup-complete"
  },
  BOOKING_HISTORY: {
    name: "booking-history",
    path: "/booking-history",
    component: "booking-history"
  },
  DELIVERY_SUCCESS: {
    name: "delivery-success",
    path: "/delivery-success",
    component: "delivery-success"
  },
  RESERVE_VIEW: {
    name: "reserve-booking",
    path: "/reserve-booking",
    component: "reserve-booking"
  },
  CHECKOUT_READY: {
    name: "checkout-ready",
    path: "/checkout-ready",
    component: "checkout-ready"
  },
  STATUS_PAGE: {
    name: "status-page",
    path: "/status-page",
    component: "status-page"
  },
  BUY_BOOKING_VIEW: {
    name: "buy-booking",
    path: "/buy-booking",
    component: "buy-booking"
  },
  SUCCESS_VIEW: {
    name: "ve-success",
    path: "/payment-callback",
    component: "ve-success"
  },
  PAYMENT_STATUS: {
    name: "payment-status",
    path: "/payment-status",
    component: "payment-status"
  },
  DELIVERY_DETAILS: {
    name: "delivery-details",
    path: "/delivery-details",
    component: "delivery-details"
  },
  DELIVERY_ADDRESS_DETAILS: {
    name: "delivery-address-details",
    path: "/delivery-address-details",
    component: "delivery-address-details"
  },
  BILLING_DETAILS: {
    name: "billing-details",
    path: "/billing-details",
    component: "billing-details"
  },
  CHECKOUT: {
    name: "checkout",
    path: "/checkout",
    component: "checkout"
  },
  FULLPAY_POLICY: {
    name: "fullpay-policy",
    path: "/fullpay-policy",
    component: "fullpay-policy"
  },
  BOOKING_SUCCESSFULL: {
    name: "booking-successfull",
    path: "/booking-successfull",
    component: "booking-successfull"
  },
  PAYMENT_VERIFICATION: {
    name: "payment-verification",
    path: "/payment-verification",
    component: "payment-verification"
  },
  OFS_REVIEW: {
    name: "ve-ofs-review",
    path: "/ofs-review/success",
    component: "ve-ofs-review"
  },
  OFS_REVIEW_FAILED: {
    name: "ve-ofs-review-failed",
    path: "/ofs-review/failed",
    component: "ve-ofs-review"
  },
  PAYMENT_UPDATES: {
    name: "ve-payment-updates",
    path: "/payment-updates",
    component: "ve-payment-updates"
  },
  VIRTUAL_ACCOUNT: {
    name: "virtual-account",
    path: "/virtual-account",
    component: "virtual-account"
  },
  RESERVATION_CANCELLED: {
    name: "reservation-cancelled",
    path: "/reservation-cancelled",
    component: "reservation-cancelled"
  },
  THREE_SIXTY_VIEW: {
    name: "web-three-sixty-view",
    path: "/three-sixty/:carId",
    component: "web-three-sixty-view"
  },
  CARE_PACKAGE: {
    name: "care-package",
    path: "/care-package",
    component: "care-package"
  },
  LOGIN: {
    name: "login-page",
    path: "/login",
    component: "login-page"
  },
  SELL_CAR: {
    name: "ve-sell-car",
    path: "/sell-car",
    component: "ve-sell-car"
  },
  MY_ACCOUNTS: {
    name: "ve-my-accounts",
    path: "/my-accounts/:tabIndex",
    component: "my-accounts"
  },
  FAQ_PAGE: {
    name: "faq-page",
    path: "/faq-page",
    component: "faq-page"
  },
  PRIVACY_POLICY: {
    name: "ve-privacy-policy",
    path: "/privacy-policy",
    component: "ve-privacy-policy"
  },
  TERMS_CONDITIONS: {
    name: "ve-terms-conditions",
    path: "/terms-conditions",
    component: "ve-terms-conditions"
  },
  COOKIE_POLICY: {
    name: "ve-cookie-policy",
    path: "/cookie-policy",
    component: "ve-cookie-policy"
  },
  SELLER_DASHBOARD: {
    name: "ve-seller-dashboard",
    path: "/sell-pre-owned-car",
    component: "ve-seller-dashboard"
  },
  SELLER_CONFIRM_CAR: {
    name: "ve-seller-confirm-car",
    path: "/seller-confirm-car",
    component: "ve-seller-confirm-car"
  },
  SELL_BIKE: {
    name: "ve-sell-bike",
    path: "/sell-bike",
    component: "ve-sell-bike"
  },
  SELLER_COMING_SOON: {
    name: "ve-seller-coming-soon",
    path: "/seller-coming-soon",
    component: "ve-seller-coming-soon"
  },
  BUY_BACK_PITCH: {
    name: "ve-buy-back-pitch",
    path: "/buy-back-pitch",
    component: "ve-buy-back-pitch"
  },
  YARD_SALES: {
    name: "ve-yard-sales",
    path: "/cars-outlet/:city",
    component: "ve-yard-sales"
  },
  DEALER_LANDING: {
    name: "ve-dealer-landing",
    path: "/dealer",
    component: "ve-dealer-landing"
  },
  DEALER_LISTINGS: {
    name: "ve-dealer-listings",
    path: "/dealer-listings/:filters",
    component: "ve-dealer-listings"
  },
  DEALER_DETAIL_VIEW: {
    name: "ve-dealer-details",
    path: "/dealer-details/:carId",
    component: "ve-dealer-details"
  },
  USER_DETAILS: {
    name: "ve-user-details",
    path: "/user-details",
    component: "ve-user-details"
  },
  FAVOURITE_CARS: {
    name: "favourite-page",
    path: "/favourite",
    component: "favourite-page"
  },
  BLOGS: {
    name: "ve-blogs",
    path: "/blog/:category?",
    component: "ve-blogs"
  },
  OUTLETS_LISTINGS: {
    name: "ve-outlets-listings",
    path: "/outlets-listings",
    component: "ve-outlets-listings"
  },
  BLOG_DETAILS: {
    name: "ve-blog-details",
    path: "/blog-details/:slugId",
    component: "ve-blog-details"
  },
  TEST_DRIVE_LOCATION_SEARCH: {
    name: "ve-td-location-search",
    path: "/td-location-search",
    component: "ve-td-location-search"
  },
  TEST_DRIVE_ADDRESS_FORM: {
    name: "ve-td-address-form",
    path: "/td-address-form",
    component: "ve-td-address-form"
  },
  TEST_DRIVE_DATE_PICKER: {
    name: "ve-td-date-picker",
    path: "/td-date-picker",
    component: "ve-td-date-picker"
  },
  PITCH: {
    name: "ve-pitch",
    path: "/pitch",
    component: "ve-pitch"
  }
};

var router = new VueRouter({
  mode: "history", //to set it to html5mode, other value can be 'hash' for hashband method
  base: window.owConfig.apiBase, //this is needed in html5mode to calculate relative url
  scrollBehavior(to, from, savedPosition) {
    if (to.path !== from.path && !this.isMobile) {
      document.getElementById("app").scrollIntoView();
    }
    return { x: 0, y: 0 };
  },
  routes: Object.values(ROUTES)
    .map(route => {
      return {
        name: route.name,
        path: route.path,
        component(resolve) {
          owLazyRoute(route.component, resolve);
        },
        meta: { preload: true }
      };
    })
    .concat([
      {
        path: "/*",
        redirect: {
          name: ROUTES.HOME.name
        }
      }
    ])
});

/* Allowing CTA/Navigation clicks from the dashboard */
const allowedDashboardForwardRoutes = [
  ROUTES.DETAIL_VIEW.name,
  ROUTES.BOOKING_HISTORY.name,
  ROUTES.SELL_CAR.name,
  ROUTES.MY_ACCOUNTS.name,
  ROUTES.LOGIN.name,
  ROUTES.PRIVACY_POLICY.name,
  ROUTES.TERMS_CONDITIONS.name,
  ROUTES.FAQ_PAGE.name,
  ROUTES.COOKIE_POLICY.name,
  ROUTES.SELLER_PITCH.name,
  ROUTES.SELLER_DASHBOARD.name,
  ROUTES.SELLER_COMING_SOON.name,
  ROUTES.BUY_BACK_PITCH.name,
  ROUTES.YARD_SALES.name,
  ROUTES.USER_DETAILS.name,
  ROUTES.FAVOURITE_CARS.name,
  ROUTES.BLOGS.name,
  ROUTES.OUTLETS_LISTINGS.name,
  ROUTES.BLOG_DETAILS.name,
  ROUTES.PITCH.name
];

function shouldPreventAwayNavigationFromDashboard(to, from) {
  return (
    from.name === ROUTES.HOME.name &&
    to.name !== from.name &&
    allowedDashboardForwardRoutes.indexOf(to.name) === -1
  );
}

function setUtmTags(to) {
  if (to && to.query) {
    for (let k in to.query) {
      localStorage.setItem(k, to.query[k]);
    }
  }
  return true;
}

function preventWithoutLogin(to) {
  /* ROUTES which are not behind the login */
  const openRoutes = [
    ROUTES.HOME.name,
    ROUTES.HOME_UTMS.name,
    ROUTES.DETAIL_VIEW.name,
    ROUTES.LOGIN.name,
    ROUTES.PRIVACY_POLICY.name,
    ROUTES.COOKIE_POLICY.name,
    ROUTES.TERMS_CONDITIONS.name,
    ROUTES.THREE_SIXTY_VIEW.name,
    ROUTES.FAQ_PAGE.name,
    ROUTES.GALLERY_VIEW.name,
    ROUTES.FEATURES_VIEW.name,
    ROUTES.SELLER_PITCH.name,
    ROUTES.SELLER_ENTRY.name,
    ROUTES.SELL_CAR.name,
    ROUTES.SELLER_DASHBOARD.name,
    ROUTES.SELL_BIKE.name,
    ROUTES.SELLER_COMING_SOON.name,
    ROUTES.BUY_BACK_PITCH.name,
    ROUTES.YARD_SALES.name,
    ROUTES.DEALER_LANDING.name,
    ROUTES.DEALER_LISTINGS.name,
    ROUTES.DEALER_DETAIL_VIEW.name,
    ROUTES.USER_DETAILS.name,
    ROUTES.FAVOURITE_CARS.name,
    ROUTES.BLOGS.name,
    ROUTES.OUTLETS_LISTINGS.name,
    ROUTES.BLOG_DETAILS.name,
    ROUTES.PITCH.name
  ];

  if (!openRoutes.includes(to.name)) {
    let userLoggedIn = localStorage.getItem("userLoggedIn");
    if (userLoggedIn == "yes") {
      return false;
    } else if (userLoggedIn == "no") {
      return true;
    }
    // return store.state.userLoggedIn ? false : true;
  } else {
    return false;
  }
}

const NotAllowedRoutesForLoadingCommonScripts = [
  ROUTES.HOME.name,
  ROUTES.HOME_UTMS.name,
  ROUTES.DETAIL_VIEW.name
];

const deeplinkAllowedRoutes = [ROUTES.HOME_UTMS.name, ROUTES.DETAIL_VIEW.name];

const vueInstance = new Vue();
let isAppInitialised = false;
const {
  closeWebView,
  loadCommonComponents,
  handleSSRRouteBasedLoading,
  handleRouteBasedLoading,
  loadCommonScripts
} = vueInstance;

if (window.serverData && window.serverData.isSSR) {
  handleSSRRouteBasedLoading(window.location.pathname);
}

router.beforeEach((to, from, next) => {
  if (deeplinkAllowedRoutes.includes(to.name)) {
    if (to.fullPath.includes("%")) {
      let parsedFullPath = decodeURIComponent(to.fullPath);
      let pathsString = "";
      let utmsPath = "";
      if (parsedFullPath.includes("?")) {
        pathsString = parsedFullPath.split("?")[0];
        utmsPath = parsedFullPath.split("?")[1];
        let utmsArray = utmsPath.split("&");
        let queryObj = {};
        utmsArray.forEach(item => {
          queryObj[item.split("=")[0]] = item.split("=")[1];
        });
        router.push({
          path: pathsString,
          query: queryObj
        });
        next(false);
      }
    }
  }

  // reset performance timestamps captured
  if (window.loadStartTimestamp && from.name && from.name !== to.name) {
    window.loadStartTimestamp = new Date().getTime();
  }

  if (from.name && from.name !== to.name) {
    window.imageCount = {};
    window.loadedImageCount = {};
    if (store) {
      store.dispatch("IMAGES_SORTED_TIME", []);
    }
  }

  const transitionName = to.params.transitionName;

  if (shouldPreventAwayNavigationFromDashboard(to, from, next)) {
    closeWebView();
    return next(false);
  }

  setUtmTags(to);

  if (store) {
    store.dispatch("SET_TRANSITION_NAME", transitionName || "");
    /* Check if chatbot is opened. If opened don't change the route but close the view */
    if (store.state.chatbotViewShown) {
      store.dispatch("SET_CHATBOT_VIEW_SHOWN", false);
      return next(false);
    }

    /* Check if some app state was stored in local storage before app unmount
     *  User directly lands on either payment status or reserve page */
    if (!from.name) {
      if (
        to.name === ROUTES.SUCCESS_VIEW.name ||
        to.name === ROUTES.RESERVE_VIEW.name
      ) {
        const carId = localStorage.getItem("carId");
        const omsOrderId = localStorage.getItem("oms_order_id");

        if (carId) {
          store.dispatch("SET_SELECTED_CAR_ID", carId);
        }

        if (omsOrderId) {
          store.dispatch("SET_ORDER_ID", omsOrderId);
        }

        const localDataFromApp = localStorage.getItem("dataFromApp");
        if (localDataFromApp) {
          try {
            window.serverData.dataFromApp = Object.assign(
              JSON.parse(localDataFromApp),
              window.serverData.dataFromApp || {}
            );
          } catch (e) {
            console.log(
              "Some error occurred during fetching items from local storage :: ",
              e.message
            );
          }
        }

        const selectedCityData = localStorage.getItem("selectedCityData");
        if (selectedCityData) {
          store.dispatch(
            "SET_SELECTED_CITY_DATA",
            JSON.parse(selectedCityData)
          );
        }
      }
    }
  }

  if (to.matched.some(m => m.meta.preload)) {
    if (!isAppInitialised) {
      loadCommonComponents();
    }
    if (!NotAllowedRoutesForLoadingCommonScripts.includes(to.name)) {
      loadCommonScripts();
    }
    handleRouteBasedLoading(to.path);

    let isWebView = window.serverData.isWebview;
    if (!isWebView && window.localStorage) {
      let lsIsWebview = localStorage.getItem("isWebview");
      isWebView = lsIsWebview == "true" ? true : false;
    }

    if (!isWebView && preventWithoutLogin(to, next)) {
      // special case for sell car issue
      let allowRedirectQuery = [
        ROUTES.ADDRESS_PIN_MAP.name,
        ROUTES.RESERVE_VIEW.name
      ];

      if (to.name == ROUTES.SELL_CAR.name) {
        router.push({ name: "login-page", query: { redirectUrl: to.name } });
      } else if (allowRedirectQuery.indexOf(to.name) > -1) {
        let query = to.query;
        router.push({ name: "login-page", query });
      } else {
        router.push({ name: "login-page", query: { redirectUrl: "" } });
      }
      return next(false);
    }

    isAppInitialised = true;
  }
  next();
});
